import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Link } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  root: {
    width: "100%",
  },
  detail: {
    display: "block",
  },
  // heading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   fontWeight: theme.typography.fontWeightRegular,
  // },
}))

const categories = [
  "Premium Pumps",
  "Spitfire Pumps",
  "MultiFlow AC/DC Hybrid",
  "Reseller Portal",
  "Veichi",
]

const videos = [
  {
    id: 1,
    cats: ["Premium Pumps"],
    title: "Wiring the Ceva, Bovem & Fortis Solar Pump Controller",
    url: "https://www.youtube.com/embed/qe1DLZ_nBm0",
  },
  {
    id: 2,
    cats: ["Spitfire Pumps"],
    title: "Spitfire Solar Water Pump Installation - Wiring the controller",
    url: "https://www.youtube.com/embed/v-4PgTJ31co",
  },
  {
    id: 3,
    cats: ["MultiFlow AC/DC Hybrid"],
    title: "MultiFlow 2 - Reset The Controller",
    url: "https://www.youtube.com/embed/tTj0nl3A5d0",
  },
  {
    id: 4,
    cats: ["Premium Pumps", "Spitfire Pumps", "MultiFlow AC/DC Hybrid"],
    title: "Solar Panels: Understanding Series & Parallel Connections",
    url: "https://www.youtube.com/embed/0Bx7_qNcCd8",
  },
  {
    id: 5,
    cats: ["Premium Pumps", "Spitfire Pumps", "MultiFlow AC/DC Hybrid"],
    title: "Replacing the Helical Rotor of a Solar Water Pump",
    url: "https://www.youtube.com/embed/PsJtHN0O3Bg",
  },
  {
    id: 6,
    cats: ["Reseller Portal"],
    title: "Sizing solar pumps in the Cedar Solar Reseller Portal",
    url: "https://www.youtube.com/embed/0fROJM5FnSA",
  },
  {
    id: 7,
    cats: ["Premium Pumps", "Spitfire Pumps", "MultiFlow AC/DC Hybrid"],
    title: "How to make a cable joint for a solar water pump",
    url: "https://www.youtube.com/embed/TeCNtyuqdOc",
  },
  {
    id: 8,
    cats: ["Spitfire Pumps"],
    title: "Spitfire Econo Range - Controller Functions Quick Guide",
    url: "https://www.youtube.com/embed/cwMwtFrPdIg",
  },
  {
    id: 9,
    cats: ["Spitfire Pumps"],
    title: "Spitfire Pumps - Reversing the Float Switch (Well to Tank)",
    url: "https://www.youtube.com/embed/rZ3avadOPEQ",
  },
  {
    id: 11,
    cats: ["Spitfire Pumps"],
    title: "Resetting the controller",
    url: "https://www.youtube.com/embed/LKqqVVm0DOY",
  },
  {
    id: 12,
    cats: ["Spitfire Pumps"],
    title: "Manage speed settings",
    url: "https://www.youtube.com/embed/FUQbGWJeB4g",
  },
  {
    id: 13,
    cats: ["Spitfire Pumps"],
    title: "Switch off built in sensor",
    url: "https://www.youtube.com/embed/X3qhGM-gSE8",
  },
  {
    id: 14,
    cats: ["Spitfire Pumps"],
    title: "Switch on built in sensor",
    url: "https://youtube.com/embed/8o8VPD8itu0",
  },
  {
    id: 15,
    cats: ["MultiFlow AC/DC Hybrid"],
    title: "MultiFlow 2 - Speed Settings (temporary & permanent)",
    url: "https://www.youtube.com/embed/QYPC5D0xxBg",
  },
  {
    id: 15,
    cats: ["Veichi"],
    title: "Veichi SI23 First Set-up",
    url: "https://www.youtube.com/embed/igsa1fyNP2c",
  },
  {
    id: 16,
    cats: ["MultiFlow AC/DC Hybrid"],
    title: "Multiflow II Installation Video - Controller Connection",
    url: "https://www.youtube.com/embed/2yg8fQDhEdI",
  }
]

const AppTraining = () => {
  const classes = useStyles()
  return (
    <Container component="main" maxWidth="md">
      <Box mb={2}>
        <Typography component="h1" variant="h4">
          Training
        </Typography>
        <Typography component="h2" variant="body1">
          Videos are grouped into product ranges and categories, please click on
          the arrow to show videos based on the category.
        </Typography>
        <Typography variant="body1">
          Send your clients a link to videos <Link href="https://www.cedarsolar.com/downloads">www.cedarsolar.com/downloads</Link>
        </Typography>
        <br />
        <div className={classes.root}>
          {categories.map(cat => (
            <Accordion key={cat}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={cat}
                id={cat}
              >
                <Typography component="h3" variant="h5">
                  {cat}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.detail}>
                {videos
                  .filter(b => b.cats.includes(cat))
                  .map(video => (
                    <div key={`${video.id}${cat}`}>
                      <Typography>{video.title}</Typography>
                      <br />
                      <iframe
                        title={video.title}
                        width={
                          window.innerWidth < 500 ? window.innerWidth - 20 : 560
                        }
                        height="315"
                        src={video.url}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                      <br />
                      <br />
                    </div>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>

        <br />
        <br />
        <Typography component="h4" variant="h6">
          Subscribe for more at{" "}
          <a
            href="http://www.youtube.com/cedarsolar"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cedar Solar YouTube
          </a>
        </Typography>
      </Box>
    </Container>
  )
}

export default AppTraining
